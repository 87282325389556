import React, { useState } from 'react';
import { IAgentLight } from '../../../../lib/models/view-models/agent';
import Avatar from '../../../common/components/avatar';
import { useCatalogTranslation } from 'src/lib/hooks/use-catalog-translations';
import { Mail } from 'iconoir-react';

const AgentView = ({ agent }: { agent: IAgentLight }) => {
  const { ct } = useCatalogTranslation('homepage');
  const defaultColor = '#6B6868';
  const whiteColor = '#ffffff';
  const [iconColor, setIconColor] = useState(defaultColor);

  return (
    <div className="w-full h-full flex-1 justify-center flex flex-col gap-4">
      <div className="h-10 w-10 relative">
        <Avatar
          firstname={agent?.firstName}
          lastname={agent?.lastName}
          email={agent?.email}
          size="md"
          charsLength={2}
          color="bg-primary-30 text-white"
          radius="full"
        />
      </div>
      <div className="flex items-end justify-between">
        <div className="flex flex-col gap-1">
          <div className="font-header text-xl text-[#1A1F23]">
            {agent?.firstName} {agent?.lastName}
          </div>
          <a
            href={`mailto:${agent?.email}`}
            className="homePage-user flex items-center gap-2 pt-2 rounded cursor-pointer"
            onMouseEnter={() => setIconColor(whiteColor)}
            onMouseLeave={() => setIconColor(defaultColor)}
          >
            <button className="flex items-center justify-center h-7 w-7 rounded-full bg-grey2-10 transition-colors">
              <Mail width={16} height={16} color={iconColor} />
            </button>
            {agent?.email}
          </a>
        </div>
      </div>
    </div>
  );
};

export default AgentView;
