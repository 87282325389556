import { OrdersResponse } from '../../data/order-data';
import convert, { Length } from 'convert';

import {
  FulfillmentFromApi,
  OrderFromApi,
  PaymentFromApi,
} from '../../models/api/order-from-api';
import { IStandardApiResponse } from '../../models/api/standard-api-response';
import {
  IFulfillment,
  IOrder,
  IOrderItem,
  IOrderLight,
  OrderComputedStatus,
  OrderPaymentStatus,
} from '../../models/view-models/order';
import { buildStoreVariant } from '../products';
import { buildAddress, buildRegion } from '../address';
import { buildShippingMethod } from '../cart';
import { StoreSettings } from '@lib/hooks/use-settings';
import { ILineItem } from '@lib/models/view-models/line-item';
import { buildDiscount } from '../discount';
import { LineItemFromApi } from '@lib/models/api/line-item-from-api';
import { DiscountFromApi } from '@lib/models/api/discount-from-api';
import { groupBy } from 'lodash';

export const buildOrdersEntities = (
  data: OrdersResponse,
): IStandardApiResponse & { orderEntities: Record<string, OrderFromApi> } => {
  const orderEntities: Record<string, OrderFromApi> = {};
  data.orders.forEach((o) => {
    orderEntities[o.id] = o;
  });
  return { ...data, orderEntities };
};

export const buildOrdersLightModel = (
  orders: OrderFromApi[],
): IOrderLight[] => {
  return orders.map((o) => {
    const payments = o.payments || [];
    return {
      id: o.id,
      displayId:
        o.external_id || o.seller_order_id || `CATALOG_${o.display_id}`,
      externalId: o.external_id,
      createdAt: o.created_at ? new Date(o.created_at) : null,
      updatedAt: o.updated_at ? new Date(o.updated_at) : null,
      status: o.status,
      origin: o.origin,
      refsCount: o.items.length,
      articlesCount: o.items?.reduce((acc, i) => {
        if (i.variant.metadata.display_unit) {
          return acc + 1;
        } else {
          return acc + i.quantity;
        }
      }, 0),
      total: o.total / 100,
      subtotal: o.subtotal / 100,
      packagingTotal: 0,
      shippingTotal: o.shipping_total / 100,
      taxTotal: o.tax_total / 100,
      discountTotal: o.discount_total / 100,
      images: o.items.map((i) => i.thumbnail),
      fulfillmentStatus: o.fulfillment_status,
      paymentStatus: o.payment_status,
      deliveryDate: o.delivery_date ? new Date(o.delivery_date) : null,
      computedStatus: computeOrderStatus(o.status, o.fulfillment_status),
      computedPaymentStatus: computeOrderPaymentStatus(payments),
      fulfillments:
        o.fulfillments?.map((ff) =>
          buildFulfillment(ff, o.delivery_date && new Date(o.delivery_date)),
        ) || null,
    };
  });
};

export const buildFullOrder = (
  order: OrderFromApi,
  displaySettings: StoreSettings,
): IOrder => {
  const discountDic = Object.fromEntries(
    (order.discounts || []).map((d) => [d.id, d]),
  );
  const getDelay = (delayFromApi: string | number): number =>
    +delayFromApi || null;
  const packagingPrice =
    (order.shipping_methods?.[0]?.data?.included_packaging_price as number) ||
    0;
  const adjustments = order.items?.flatMap((i) => i.adjustments || []) || [];
  const adjustmentsDic = groupBy(adjustments, (a) => a.discount_id);
  const payments = order.payments || [];
  return order
    ? {
        id: order.id,
        origin: order.origin,
        displayId:
          order.external_id ||
          order.seller_order_id ||
          `CATALOG_${order.display_id}`,
        externalId: order.external_id,
        createdAt: order.created_at ? new Date(order.created_at) : null,
        updatedAt: order.updated_at ? new Date(order.updated_at) : null,
        deliveryDate: order.delivery_date
          ? new Date(order.delivery_date)
          : null,
        status: order.status,
        refsCount: order.items?.length,
        articlesCount: order.items?.reduce((acc, i) => {
          if (i.variant.metadata.display_unit) {
            return acc + 1;
          } else {
            return acc + i.quantity;
          }
        }, 0),
        total: order.total / 100,
        images: order.items?.map((i) => i.thumbnail || '/placeholder.png'),
        items: order.items
          ?.map((i) => buildOrderItem(i, discountDic, displaySettings))
          .sort((a, b) => a.rank - b.rank),
        shippingAddress: buildAddress(order.shipping_address),
        billingAddress: buildAddress(order.billing_address),
        subtotal: order.subtotal / 100,
        packagingTotal: packagingPrice / 100,
        shippingTotal: (order.shipping_total - packagingPrice) / 100,
        taxTotal: order.tax_total / 100,
        discounts: order.discounts?.map((d) =>
          buildDiscount(d, adjustmentsDic[d.id]),
        ),
        paymentMethod: order.payments?.length
          ? order.payments[0].provider_id.toLowerCase() === 'stripe'
            ? 'CB'
            : order.payments[0].data?.payment_method
          : null,
        paymentDelay: getDelay(
          order?.payments?.[0]?.data?.payment_method_data?.delay,
        ),
        comment: order.metadata?.comment ? order.metadata?.comment : '',
        cartId: order.cart_id,
        fulfillmentStatus: order.fulfillment_status,
        paymentStatus: order.payment_status,
        discountTotal: order.discount_total / 100,
        giftCardTotal: order.gift_card_total / 100,
        shippingMethods: order.shipping_methods?.map(buildShippingMethod),
        region: buildRegion(order.region),
        receiptUrl: order.receipt_url,
        computedStatus: computeOrderStatus(
          order.status,
          order.fulfillment_status,
        ),
        computedPaymentStatus: computeOrderPaymentStatus(payments),
        fulfillments: order.fulfillments?.map((ff) =>
          buildFulfillment(
            ff,
            order.delivery_date && new Date(order.delivery_date),
          ),
        ),
        invoiceUrl: order.metadata?.invoice_url,
        deliveryNoteUrl: order.metadata?.delivery_note_url,
      }
    : null;
};

export const buildOrderItem = (
  item: LineItemFromApi,
  discounts: { [discount_id: string]: DiscountFromApi },
  displaySettings: StoreSettings,
): IOrderItem => {
  if (!item) return null;
  const displayUnit = item.variant.metadata.display_unit as Length;
  const storageUnit = item.variant.metadata.storage_unit as Length;
  const decimals = !!displayUnit && !!storageUnit;

  return {
    id: item.id,
    rank: item.rank,
    createdAt: item.created_at ? new Date(item.created_at) : null,
    updatedAt: item.updated_at ? new Date(item.updated_at) : null,
    quantity: decimals
      ? convert(item.quantity, storageUnit).to(displayUnit)
      : item.quantity,
    variantId: item.variant_id,
    thumbnail: item.variant?.thumbnail || item.thumbnail || '/placeholder.png',
    title: item.title,
    unitPrice: decimals
      ? convert(item.unit_price / 100, displayUnit).to(storageUnit)
      : item.unit_price / 100,
    variant: buildStoreVariant(
      item.variant,
      displaySettings,
      undefined,
      undefined,
      item.metadata?.is_preorder === true,
    ),
    orderId: item.order_id,
    metadata: item.metadata,
    subtotal: item.subtotal / 100,
    total: item.total / 100,
    fulfilledQuantity: item.fulfilled_quantity ?? 0,
    shippedQuantity: item.shipped_quantity ?? 0,
    hasShipping: item.has_shipping,
    lineDiscount: item.adjustments
      ? item.adjustments
          ?.filter((a) => discounts[a.discount_id]?.rule?.allocation === 'item')
          .reduce((acc, a) => acc + a.amount, 0) / 100
      : 0,
    discountTotal: item.discount_total / 100,
    displayUnit,
    storageUnit,
  };
};

const buildFulfillment = (
  ff: FulfillmentFromApi,
  orderdeliveryDate: Date,
): IFulfillment => {
  return {
    id: ff.id,
    orderId: ff.order_id,
    shippedAt: ff.shipped_at ? new Date(ff.shipped_at) : null,
    canceled: !!ff.canceled_at,
    items: ff.items.map((i) => ({
      fulfillmentId: i.fulfillment_id,
      itemId: i.item_id,
      quantity: i.quantity,
      item: { variantId: i.item?.variant_id } as ILineItem,
    })),
    trackingUrl: computeTrackingUrl(ff),
    deliveryNoteUrl: null, //TODO: not managed yet
    deliveryDate: orderdeliveryDate, //TODO: this is the fallback, we need to manage the delivery date for each fulfillment,
    invoiceUrl: ff.metadata?.invoice_url,
  };
};

const computeOrderStatus = (
  orderStatus: IOrderLight['status'],
  fulfillmentStatus: IOrderLight['fulfillmentStatus'],
): OrderComputedStatus | null => {
  if (orderStatus === 'reserved') {
    return OrderComputedStatus.RESERVED;
  }
  if (orderStatus === 'canceled') {
    return OrderComputedStatus.CANCELED;
  }
  if (orderStatus === 'rejected') {
    return OrderComputedStatus.REJECTED;
  }

  if (orderStatus === 'pending' && fulfillmentStatus === 'not_fulfilled') {
    return OrderComputedStatus.PENDING;
  }

  if (orderStatus === 'completed') {
    if (
      fulfillmentStatus === 'not_fulfilled' ||
      fulfillmentStatus === 'canceled'
    ) {
      return OrderComputedStatus.VALIDATED;
    }
    if (fulfillmentStatus === 'partially_fulfilled') {
      return OrderComputedStatus.PARTIALLY_FULFILLED;
    }
    if (fulfillmentStatus === 'fulfilled') {
      return OrderComputedStatus.READY_TO_SHIP;
    }
    if (fulfillmentStatus === 'partially_shipped') {
      return OrderComputedStatus.PARTIALLY_SHIPPED;
    }
    if (fulfillmentStatus === 'shipped') {
      return OrderComputedStatus.SHIPPED;
    }
  }
  console.error(
    `Failed to compute status: order=${orderStatus}, fulfillment=${fulfillmentStatus}`,
  );

  return null;
};

const computeOrderPaymentStatus = (
  payments: PaymentFromApi[],
): OrderPaymentStatus => {
  if (payments.length === 0) {
    return OrderPaymentStatus.NOT_PAID;
  }
  let paid = 0;
  let notPaid = 0;
  for (const payment of payments) {
    if (payment.captured_at) {
      paid++;
    } else {
      notPaid++;
    }
  }
  if (paid === 0) {
    return OrderPaymentStatus.NOT_PAID;
  }
  if (notPaid === 0) {
    return OrderPaymentStatus.PAID;
  }
  return OrderPaymentStatus.PARTIALLY_PAID;
};

// TODO: do the real implementation when we will manage the real shiping providers
const computeTrackingUrl = (ff: FulfillmentFromApi): string => {
  return ff.tracking_numbers?.length
    ? `${getBaseTrackingUrl(ff.provider_id)}${ff.tracking_numbers.join(',')}`
    : ff.tracking_links?.[0]?.url ||
        ff.tracking_links?.[0]?.tracking_number ||
        null;
};

const getBaseTrackingUrl = (provider_id: string): string => {
  switch (provider_id) {
    default:
      return 'https://m.17track.net/fr/track-details?nums=';
  }
};
