import React from 'react';

type TagProps = {
  label: string;
  size: 'sm' | 'md' | 'lg';
  color:
    | 'red'
    | 'orange'
    | 'green'
    | 'blue'
    | 'purple'
    | 'grey'
    | 'white'
    | 'light-green';
  clickAction?: () => void;
  children?: React.ReactNode;
};

function classNames(...classes) {
  return classes.filter(Boolean).join(' ').replaceAll(',', ' ');
}

function Tag({ label, size, color, children }: TagProps): JSX.Element {
  let classes: string[] = [
    'flex',
    'justify-center',
    'items-center',
    'gap-2',
    'rounded-3xl',
    'flex-shrink-0',
    'w-auto]',
  ];

  switch (size) {
    case 'sm':
      classes = [...classes, ...['px-2', 'text-xs', 'h-5', 'font-normal']];
      break;
    case 'md':
      classes = [...classes, ...['px-3', 'text-sm', 'h-7', 'font-normal']];
      break;
    case 'lg':
      classes = [...classes, ...['text-sm', 'h-12', 'font-normal']];
      break;
  }

  switch (color) {
    case 'red':
      classes = [...classes, ...['bg-error-10', 'text-error-50']];
      break;
    case 'orange':
      classes = [...classes, ...['bg-orange-10', 'text-orange-60']];
      break;
    case 'green':
      classes = [...classes, ...['bg-success-10', 'text-success-50']];
      break;
    case 'light-green':
      classes = [
        ...classes,
        ...[
          'bg-white',
          'text-success-50',
          'border-dashed',
          'border',
          'border-success-50',
        ],
      ];
      break;
    case 'blue':
      classes = [...classes, ...['bg-secondary-10', 'text-secondary-50']];
      break;
    case 'purple':
      classes = [...classes, ...['bg-[#EAE8FF]', 'text-[#6435F2]']];
      break;
    case 'grey':
      classes = [...classes, ...['bg-grey2-10', 'text-grey2-60']];
      break;
    case 'white':
      classes = [
        ...classes,
        ...['bg-white', 'border', 'border-grey2-20', 'text-grey2-60'],
      ];
      break;
  }

  return (
    <div className={classNames(classes)}>
      {children}
      <div>{label}</div>
    </div>
  );
}

export default Tag;
