import {
  InfiniteData,
  useInfiniteQuery,
  useQuery,
} from '@tanstack/react-query';
import { useEffect, useState, useMemo } from 'react';
import {
  getCustomerOrders,
  getCustomerOrderById,
  OrdersResponse,
} from '../data/order-data';
import { IOrder } from '../models/view-models/order';
import { buildFullOrder } from '../utils/orders';
import { useSettings } from './use-settings';
import { OrderFromApi } from '@lib/models/api/order-from-api';

type infiniteOrderParams = {
  fields: string;
  expand: string;
  searchId?: string | null;
  statuses?: OrderFromApi['status'][];
};

export const useInfiniteOrders = ({
  expand,
  fields,
  searchId,
  statuses,
}: infiniteOrderParams) => {
  const displaySettings = useSettings();
  if (!searchId) {
    searchId = null;
  }
  if (!statuses?.length) {
    statuses = null;
  }
  const buildOrdersSelector = (data: InfiniteData<OrdersResponse>) => {
    return {
      ...data,
      pages: data.pages.map((page) => {
        return {
          ...page,
          orders: page.orders.map((order) =>
            buildFullOrder(order, displaySettings),
          ),
        };
      }),
    };
  };

  const { data, error, fetchNextPage, hasNextPage, isFetching } =
    useInfiniteQuery(
      ['orders', { limit: 20, expand, fields, searchId, statuses }],
      getCustomerOrders,
      {
        select: buildOrdersSelector,
        getNextPageParam: (lastPage) => {
          if (lastPage.count > lastPage.offset + lastPage.limit) {
            return lastPage.offset + lastPage.limit;
          }
          return undefined;
        },
      },
    );

  const orders = useMemo((): IOrder[] => {
    if (!data) {
      return null;
    }
    return data.pages.reduce((acc, page) => {
      return [...acc, ...page.orders];
    }, []);
  }, [data]);
  return {
    orders,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    count: data?.pages[0].count,
  } as const;
};

export const useOrdersForHomepage = ({
  expand,
  fields,
  smartOrderDisplayed = false,
}: {
  fields?: string;
  expand?: string;
  smartOrderDisplayed?: boolean;
}) => {
  const displaySettings = useSettings();
  const [limit, setLimit] = useState(smartOrderDisplayed ? 1 : 3);
  useEffect(() => {
    setLimit(smartOrderDisplayed ? 1 : 3);
  }, [smartOrderDisplayed]);

  useEffect(() => {
    function handleResize() {
      if (
        window.matchMedia('(max-width: 512px)').matches ||
        smartOrderDisplayed
      ) {
        setLimit(1);
        return;
      }
      if (window.matchMedia('(max-width: 768px)').matches) {
        setLimit(2);
        return;
      }
      const ordersToDisplay = smartOrderDisplayed ? 1 : 3;
      setLimit(ordersToDisplay);
    }
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const buildOrdersSelector = (data: OrdersResponse) => {
    return data.orders.map((o) => buildFullOrder(o, displaySettings));
  };

  const queryKey = ['orders', { expand, fields, limit }];
  const { data, isError, isLoading } = useQuery(queryKey, getCustomerOrders, {
    select: buildOrdersSelector,
  });

  return { orders: data, isError, isLoading } as const;
};

export const useCompletedOrder = (id: string) => {
  const displaySettings = useSettings();
  const queryKey = ['order', id];
  const buildFullOrderSelector = (order: OrderFromApi) =>
    buildFullOrder(order, displaySettings);
  const { data } = useQuery(queryKey, getCustomerOrderById, {
    enabled: !!id,
    select: buildFullOrderSelector,
  });

  return { ...data } as const;
};
