import React from 'react';
import Image from 'next/image';

const ExploreCatatlogThumbnail = ({
  image,
  setImageSrcError,
}: {
  image: { src: string; alt: string };
  setImageSrcError: (src: string) => void;
}) => {
  return (
    <div className="relative border h-16 w-16 rounded-xl bg-white border-grey2-10 shadow-xs">
      <Image
        width={64}
        height={64}
        src={image.src}
        alt={image.alt}
        placeholder="blur"
        blurDataURL="/placeholder.png"
        className="absolute object-contain rounded-xl w-16 h-auto"
        onError={() => {
          setImageSrcError(image.src);
        }}
      />
    </div>
  );
};

export default ExploreCatatlogThumbnail;
