import React from 'react';
import Image from 'next/image';
import { useCatalogTranslation } from '@lib/hooks/use-catalog-translations';
import { GoogleDocs } from 'iconoir-react';
import { EURO } from '../../../../lib/utils/helpers/get-formated-price';
import { OrderCardProps } from '.';

const DraftOrderCard = ({
  displayId,
  entityId,
  images,
  handleClick,
  totalArticles,
  subtotal,
  date,
  type,
}: OrderCardProps) => {
  const { ct } = useCatalogTranslation('common');
  const imagesToDisplay = images.length >= 3 ? images?.slice(0, 2) : images;

  return (
    <button className="h-full" onClick={() => handleClick(entityId)}>
      <div className="flex flex-col gap-5 items-start justify-between h-full">
        <div className="flex items-center gap-2">
          <div className="flex items-center justify-center w-7 h-7 bg-grey2-10 rounded-full">
            <GoogleDocs className="w-4 h-4 text-grey2-60" />
          </div>
          <div className="font-header text-large leading-[18px] font-semibold">
            {type === 'quotes'
              ? ct('OrderOrCart.quotation', 'Devis')
              : ct('OrderOrCart.reservations', 'Reservation')}
          </div>
          <div>#{displayId}</div>
        </div>
        <div className="flex items-center gap-2">
          {imagesToDisplay.length > 0 && (
            <div className="relative w-10">
              {imagesToDisplay.map((image, index) => {
                return (
                  <div
                    key={`${image}${index}`}
                    className="absolute h-6 w-6 -top-3 rounded-md bg-grey-5"
                    style={{ zIndex: index, left: `${index * 12}px` }}
                  >
                    <Image
                      width={600}
                      height={600}
                      src={image}
                      alt="Product"
                      className="aspect-square w-full rounded-md border-2 border-white"
                    />
                  </div>
                );
              })}
            </div>
          )}
          <div data-testid="totalArticles" className="text-sm text-grey2-90">
            {totalArticles}{' '}
            {ct('OrderOrCart.articles', {
              count: totalArticles,
            })}
          </div>
          <div
            data-testid="subtotal"
            className="text-sm font-semibold text-[#252C32]"
          >
            {EURO(subtotal)}
          </div>
        </div>
        <div className="flex flex-col gap-2 w-full mt-auto">
          <div className="w-full border-b border-grey2-10"></div>
          <div className="w-full flex justify-between items-baseline">
            <div className="text-base text-grey2-60">
              {ct('OrderOrCart.smallDate', { date: new Date(date) })}
            </div>
          </div>
        </div>
      </div>
    </button>
  );
};

export default DraftOrderCard;
