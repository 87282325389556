import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { IButtonProps } from '../../../common/components/catalog-button';

const FilledStateButton = forwardRef<HTMLButtonElement, IButtonProps>(
  ({ children }, ref) => {
    const innerRef = useRef<HTMLButtonElement>(null);
    useImperativeHandle(ref, () => innerRef.current!);

    return (
      <button
        ref={innerRef}
        className="absolute bottom-0 right-0 bg-seller w-[135px] h-10 group-hover:w-10 group-hover:px-0 duration-500 text-white flex items-center group-hover:justify-center gap-2 transition-all group rounded-full px-4 text-sm"
      >
        {children}
      </button>
    );
  },
);

FilledStateButton.displayName = 'FilledStateButton';

export default FilledStateButton;
