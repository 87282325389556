import React from 'react';
import { useCatalogTranslation } from '../../../../lib/hooks/use-catalog-translations';

function NoOrderRow(): JSX.Element {
  const { ct } = useCatalogTranslation('homepage');

  return (
    <div className="mx-auto w-fit h-full shrink-0 flex justify-center items-center">
      <div className="flex flex-col basis-full items-center justify-center gap-4 text-base">
        <div className="flex w-full grow  flex-col items-center gap-3">
          <div className="w-full rounded-3xl h-2 bg-grey2-20"></div>
          <div className="w-1/2 rounded-3xl h-2 bg-grey2-20"></div>
        </div>
        <div>
          {ct('OrderRow.emptyState', "Vous n'avez pas de commandes en cours")}
        </div>
      </div>
    </div>
  );
}

export default NoOrderRow;
