import clsx from 'clsx';
import React from 'react';

type CardProps = {
  bgClass?: string;
  children: React.ReactNode;
  width?: string | number;
  height?: number;
  border?: boolean;
  selected?: boolean;
  hoverEffect?: boolean;
  paddingClass?: 'p-2' | 'p-3' | 'p-4' | 'p-6';
  radiusBottom?: boolean;
};
function Card({
  bgClass = 'bg-white',
  children,
  height,
  width = '100%',
  border = false,
  selected = false,
  paddingClass = 'p-6',
  radiusBottom = false,
}: CardProps): JSX.Element {
  const widthClass = typeof width === 'number' ? `w-[${width}px]` : 'w-full';
  const heightStyle =
    typeof height === 'number'
      ? {
          height: `${height}px`,
          minHeight: `${height}px`,
        }
      : {
          height: '100%',
        };
  return (
    <div
      style={heightStyle}
      className={clsx(
        'group relative flex flex-col gap-2 rounded-xl overflow-hidden',
        bgClass,
        widthClass,
        paddingClass,
        { 'border border-grey2-10': border },
        { 'border-2 border-seller hover:border-seller': selected },
        { 'rounded-t-none border-t-0': radiusBottom },
      )}
    >
      {children}
    </div>
  );
}

export default Card;
