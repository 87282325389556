import Link from 'next/link';
import React, { Fragment } from 'react';
import { useFooterLinks } from '../../../../lib/hooks/use-settings';

const FooterLinks = () => {
  const footerLinks = useFooterLinks();

  return (
    <footer className="flex justify-end py-6 mt-4 border-t border-grey2-10 gap-3 xl:container mx-auto">
      {footerLinks.length > 0 &&
        footerLinks.map((link, index) => (
          <Fragment key={link.label}>
            <Link href={link.link} key={link.label}>
              <a target="_blank" className="text-grey2-50 hover:text-grey2-100">
                {link.label}
              </a>
            </Link>
            {index < footerLinks.length - 1 && (
              <span className="text-grey2-20">|</span>
            )}
          </Fragment>
        ))}
    </footer>
  );
};

export default FooterLinks;
