import { DragEvent, ChangeEvent, useRef } from 'react';
import { useCatalogTranslation } from '@lib/hooks/use-catalog-translations';
import { ArrowTrSquare, GoogleDocs, Trash } from 'iconoir-react';

type DocumentUploaderProps = {
  file: File | null;
  setFile: (file: File | null) => void;
  setErrorMessage: (errorMessage: string | null) => void;
};
const DocumentUploader = ({
  file,
  setFile,
  setErrorMessage,
}: DocumentUploaderProps) => {
  const { ct } = useCatalogTranslation('homepage');

  const maxSize = 4 * 1024 * 1024; // 4 Mo

  const fileInputRef = useRef<HTMLInputElement>(null);

  const validateFile = (file: File) => {
    if (file.size > maxSize) {
      setErrorMessage(
        ct(
          'SmartOrderCard.errorLimit',
          'Le fichier dépasse la limite de 4 Mo.',
        ),
      );
      return false;
    }
    const allowedTypes = [
      'application/pdf',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ];

    if (!allowedTypes.includes(file.type)) {
      setErrorMessage(
        ct('SmartOrderCard.errorFormat', "Le fichier n'est pas autorisé"),
      );
      return false;
    }
    setErrorMessage(null);
    return true;
  };

  const handleClick = () => {
    fileInputRef.current?.click(); // Trigger click on hidden input
  };
  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length && validateFile(files[0])) {
      setFile(files[0]);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && validateFile(e.target.files[0])) {
      setFile(e.target.files[0]);
    }
  };

  const openFileInNewTab = () => {
    if (file) {
      const url = URL.createObjectURL(file);
      window.open(url, '_blank');
    }
  };

  const deleteFile = () => {
    setFile(null);
  };

  return (
    <div
      className="px-4 py-6 text-center rounded-xl hover:border-seller transition-all outline-dashed outline-offset-8 outline-grey2-30 hover:outline-seller hover:bg-seller/5"
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      {!file ? (
        <>
          <p>
            <span className="text-grey2-60">
              {ct('SmartOrderCard.dropFileLabel', 'Déposez le fichier ici ou')}{' '}
            </span>
            <button
              className="underline font-semibold text-seller"
              onClick={handleClick}
            >
              {ct('SmartOrderCard.BrowseCta', 'Parcourez vos fichiers')}
            </button>
          </p>
          <p className="text-grey2-60 text-small">
            {ct('SmartOrderCard.fileMaxSize', 'Taille maximum - 4MO/PDF')}
          </p>
          <input
            ref={fileInputRef}
            className="hidden"
            type="file"
            accept="application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            onChange={handleChange}
          />
        </>
      ) : (
        <div className="flex justify-between p-2 bg-grey2-5 rounded-lg items-center">
          <div className="flex gap-2 items-center">
            <GoogleDocs className="w-4 h-4" />
            <span className="text-grey2-60">{file.name}</span>
          </div>
          <div className="flex w-10 h-full gap-2 items-center">
            <button onClick={openFileInNewTab}>
              <ArrowTrSquare className="w-4 h-4" />
            </button>
            <button onClick={deleteFile}>
              <Trash className="w-4 h-4" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DocumentUploader;
