import React from 'react';
import Image from 'next/image';
import { useCatalogTranslation } from '../../../../lib/hooks/use-catalog-translations';
import SplitLine from '../../../common/components/split-line';

const ExpressByEmptyState = () => {
  const { ct } = useCatalogTranslation('homepage');

  return (
    <>
      <div className="translate-y-12 group-hover:translate-y-0 duration-500 transition-transform">
        <div className="relative">
          <Image
            width={44}
            height={44}
            src="/cart-fast.svg"
            alt="Express buy"
          />
        </div>
        <div className="flex justify-between items-end">
          <div className="flex flex-col">
            <h2 className="font-header text-xl color-grey2-90">
              <SplitLine
                lines={ct(
                  'ExpressBuyCard.emptyState',
                  'Passer une \ncommande rapide',
                )}
              />
            </h2>
            <p className="text-base font-sans text-grey2-60 transition-opacity  duration-500 opacity-0 group-hover:opacity-100">
              <SplitLine
                lines={ct(
                  'ExpressBuyCard.emptyStateDesc',
                  'Passez commande rapidement en saisissant \ndes références produits ou via import',
                )}
              />
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExpressByEmptyState;
