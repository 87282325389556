import React, { useEffect, useState } from 'react';
import { useIntercom } from 'react-use-intercom';
import { useAccount } from '@lib/context/account-context';
import Image from 'next/image';
import { useCatalogTranslation } from '@lib/hooks/use-catalog-translations';

function Chat({ message }: { message: string }): JSX.Element {
  const [intercomBooted, setIntercomBooted] = useState(false);
  const { boot, showNewMessage } = useIntercom();
  const { customer } = useAccount();
  useEffect(() => {
    if (customer && !intercomBooted) {
      boot({
        userId: customer.id,
        name: customer.firstName,
        userHash: customer.metadata.intercomUserToken as string,
        email: customer.email,
        hideDefaultLauncher: true,
      });
      setIntercomBooted(true);
    }
  }, [customer]);
  const { ct } = useCatalogTranslation('common');

  const chatMessageShow = () => {
    showNewMessage(message);
  };

  return (
    <div className="fixed bottom-4 right-4 w-[72px] h-[72px] transition-all hover:bg-white hover:w-[307px] hover:rounded-[72px] hover:pl-6 group">
      <button
        onClick={chatMessageShow}
        className="absolute right-0 mt-3 mr-3 bg-primary-50 w-12 h-12 rounded-full flex justify-center items-center"
      >
        <Image src="/chat.svg" width={24} height={24} alt="contact the team" />
      </button>
      <div className="opacity-0 w-52 group-hover:opacity-100 transition-opacity flex flex-col justify-center h-full">
        <p className="font-semibold text-base">
          {ct('Common.chat', 'Un problème, une suggestion ?', { ns: 'common' })}
        </p>
        <p className="text-base">
          {ct('Common.contactSupport', 'Contactez le support Catalog', {
            ns: 'common',
          })}
        </p>
      </div>
    </div>
  );
}

export default Chat;
