import { IAddress, IRegion } from './address';
import { IShippingMethod } from './cart';
import { IDiscount } from './discount';
import { IDraftOrder } from './draft-order';
import { ILineItem } from './line-item';

export enum OrderComputedStatus {
  NONE,
  CANCELED,
  REJECTED,
  RESERVED,
  PENDING,
  VALIDATED,
  PARTIALLY_FULFILLED,
  READY_TO_SHIP,
  PARTIALLY_SHIPPED,
  SHIPPED,
}

export enum OrderPaymentStatus {
  NOT_PAID = 'not_paid',
  PAID = 'paid',
  PARTIALLY_PAID = 'partially_paid',
}

export interface IFulfillment {
  id: string;
  orderId: string;
  shippedAt?: Date;
  canceled: boolean;
  items: {
    fulfillmentId: string;
    itemId: string;
    quantity: number;
    item: ILineItem;
  }[];
  trackingUrl?: string;
  deliveryNoteUrl?: string;
  deliveryDate?: Date;
  invoiceUrl?: string;
}

export interface IPriceTotals {
  subtotal: number;
  discountTotal: number;
  packagingTotal: number;
  shippingTotal: number;
  itemTaxTotal?: number;
  taxTotal: number;
  total: number;
}

export interface IOrderLight extends IPriceTotals {
  id: string;
  origin: 'cart' | 'import' | 'document' | 'quotation';
  displayId: string;
  createdAt: Date;
  updatedAt: Date;
  status:
    | 'reserved'
    | 'pending'
    | 'completed'
    | 'canceled'
    | 'archived'
    | 'rejected'
    | 'requires_action';
  fulfillmentStatus:
    | 'canceled'
    | 'requires_action'
    | 'not_fulfilled'
    | 'partially_fulfilled'
    | 'fulfilled'
    | 'partially_shipped'
    | 'shipped'
    | 'partially_returned'
    | 'returned';
  paymentStatus:
    | 'not_paid'
    | 'awaiting'
    | 'captured'
    | 'partially_refunded'
    | 'refunded'
    | 'canceled'
    | 'requires_action';
  articlesCount: number; // total quantity of articles
  refsCount?: number; // total of different articles
  externalId?: string;
  images: string[];
  receiptUrl?: string;
  deliveryDate?: Date;
  computedStatus: OrderComputedStatus;
  computedPaymentStatus: OrderPaymentStatus;
  fulfillments: IFulfillment[];
  invoiceUrl?: string;
  deliveryNoteUrl?: string;
}

export interface IOrderItem extends ILineItem {
  metadata: Record<string, unknown>;
  orderId: string;
  fulfilledQuantity: number;
  shippedQuantity: number;
  hasShipping: boolean;
}

export interface IOrder extends IOrderLight {
  items: IOrderItem[];
  shippingAddress: IAddress;
  billingAddress: IAddress;
  paymentMethod?: string;
  paymentDelay?: number;
  shippingMethods: IShippingMethod[];
  giftCardTotal: number;
  comment?: string;
  cartId: string;
  region?: IRegion;
  discounts: IDiscount[];
}

export interface BackOrderedVariant {
  variantId: string;
  ref: string;
  thumbnail: string;
  title: string;
  orderIds: string[];
  status: { hasStock: boolean; availableOn?: Date };
  quantityInBackorder: number;
}

export type OrderTypeCardDataTitle = 'quote' | 'reservation' | 'order';

export type OrderTypeCardData = {
  id: OrderTypeCardDataTitle;
  title: string;
  description: string;
  icon: React.ReactNode;
};

export type OrderOrDraftOrderCollection = {
  collection: IOrder[] | IDraftOrder[];
  count: number;
  hasNextPage: boolean;
  fetchNextPage: () => void;
};
