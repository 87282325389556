import React from 'react';
import Image from 'next/image';
import { useAccount } from '@lib/context/account-context';
import { useSettings } from '@lib/hooks/use-settings';
import { useCatalogTranslation } from '@lib/hooks/use-catalog-translations';
import Card from '../../../common/components/card';
import SplitLine from '../../../common/components/split-line';

const WelcomeCard = () => {
  const { ct } = useCatalogTranslation('homepage');
  const { homeBanner } = useSettings();
  const { customer, company } = useAccount();
  const firstName =
    customer?.firstName ||
    customer?.email.split('@')[0].charAt(0).toUpperCase() +
      customer?.email.split('@')[0].slice(1);
  return (
    <Card border={false} height={244}>
      <div className="absolute inset-0 w-full h-[244px]">
        {homeBanner && (
          <Image
            src={homeBanner}
            layout="fill"
            loading="eager"
            priority={true}
            quality={90}
            objectFit="cover"
            alt="Photo by @thevoncomplex https://unsplash.com/@thevoncomplex"
            className="absolute inset-0"
            draggable="false"
          />
        )}
        <div className="absolute inset-0 h-full w-full bg-black/50">
          <div className="relative w-full h-full text-white flex flex-col justify-between p-6">
            <div className="flex flex-col gap-2">
              <div className="text-sm">
                {ct(
                  'WelcomeCard.welcomeMessage',
                  'Bienvenue sur vore espace acheteur',
                )}{' '}
                {company?.name}, {firstName}
              </div>
              <div className="font-header text-2xl">
                <SplitLine
                  lines={ct(
                    'WelcomeCard.question',
                    "Que souhaitez-vous faire \naujourd'hui ?",
                  )}
                />
              </div>
            </div>
            <div className="text-xs"> {ct('WelcomeCard.catalogPowered')}</div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default WelcomeCard;
