import React, { useState } from 'react';
import { useCatalogTranslation } from '@lib/hooks/use-catalog-translations';
import { useSettings } from '@lib/hooks/use-settings';
import { useMeAccount } from '@lib/hooks/use-account-me';
import { uploadOrder } from '@lib/data/order-data';
import { useToasts } from '@lib/context/toaster-context';
import CatalogButton from '../../../common/components/catalog-button';
import DocumentUploader from '../../../common/components/document-uploader';

const UploadOrderModal = ({ setOpen }: { setOpen: (b: boolean) => void }) => {
  const { ct } = useCatalogTranslation(['homepage', 'common']);
  const [file, setFile] = useState<File | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { sellerNameTrimLow } = useSettings();
  const { customer } = useMeAccount();
  const { addToast } = useToasts();

  const handleCancel = () => {
    setFile(null);
    setErrorMessage(null);
    setOpen(false);
  };
  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!file) return;
    const fomrData = new FormData();
    fomrData.append('file', file);
    fomrData.append('seller', sellerNameTrimLow);
    fomrData.append('customer_email', customer?.email);
    try {
      const response = await uploadOrder(fomrData);
      if (response.status === 204) {
        addToast({
          title: ct(
            'SmartOrderCard.successUploadTitle',
            'Fichier envoyé avec succès',
          ),
          variant: 'success',
          description: ct(
            'SmartOrderCard.successUploadDesc',
            'Votre bon de commande a été envoyé!',
          ),
        });
        setFile(null);
        setOpen(false);
      }
    } catch (error) {
      console.error('error:', error);
      setErrorMessage(
        ct('SmartOrderCard.errorUpload', 'Erreur lors de l’envoi du fichier'),
      );
    }
  };
  return (
    <div className="p-6 flex flex-col gap-5">
      <header>
        <h2 className="text-xl font-header font-bold text-black">
          {ct(
            'SmartOrderCard.uploadModalTitle',
            'Importer votre bon de commande',
            {
              ns: 'homepage',
            },
          )}
        </h2>
        <p className="text-base leading-5 text-grey2-60 mt-2">
          {ct(
            'SmartOrderCard.uploadModalDesc',
            'Cette commande sera envoyée à votre représentant commercial',
            {
              ns: 'homepage',
            },
          )}
        </p>
      </header>
      <DocumentUploader
        file={file}
        setFile={setFile}
        setErrorMessage={setErrorMessage}
      />
      {errorMessage && <p className="text-red-500 text-xs">{errorMessage}</p>}
      <div className="flex gap-3 mt-6">
        <CatalogButton color="grey" type="reset" onClick={handleCancel}>
          {ct('CommonCta.cancel', 'Annuler', { ns: 'common' })}
        </CatalogButton>
        <CatalogButton
          color="seller"
          type="submit"
          disabled={!file}
          onClick={handleSubmit}
        >
          {ct('SmartOrderCard.sendOrderCta', 'Passer commande', {
            ns: 'homepage',
          })}
        </CatalogButton>
      </div>
    </div>
  );
};

export default UploadOrderModal;
