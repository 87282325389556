import { ErrorMessage } from '@hookform/error-message';
import Eye from '@modules/common/icons/eye';
import EyeOff from '@modules/common/icons/eye-off';
import clsx from 'clsx';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import { get } from 'react-hook-form';
import { useCatalogTranslation } from '@lib/hooks/use-catalog-translations';

type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  errors?: Record<string, unknown>;
  touched?: Record<string, unknown>;
  name: string;
  showRequiredMark?: boolean;
};

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      type,
      name,
      label,
      errors,
      touched,
      required,
      showRequiredMark = true,
      ...props
    },
    ref,
  ) => {
    const { ct } = useCatalogTranslation('common');
    const optional = ct('Common.optional', 'Optionnel');
    const inputRef = React.useRef<HTMLInputElement>(null);
    const [showPassword, setShowPassword] = useState(false);
    const [inputType, setInputType] = useState(type);

    useEffect(() => {
      if (type === 'password' && showPassword) {
        setInputType('text');
      }

      if (type === 'password' && !showPassword) {
        setInputType('password');
      }
    }, [type, showPassword]);

    useImperativeHandle(ref, () => inputRef.current!);

    const hasError = get(errors, name) && get(touched, name);

    return (
      <div>
        <div className="relative z-0 flex w-full flex-col gap-1">
          <label
            htmlFor={name}
            onClick={() => inputRef.current?.focus()}
            className={clsx(
              'text-base text-grey2-90 transition-all duration-300 flex items-center gap-1',
              {
                '!text-rose-500': hasError,
              },
            )}
          >
            {label}
            {showRequiredMark && required && (
              <span className="text-rose-500">*</span>
            )}
            {showRequiredMark && !required && (
              <span className="text-grey2-40 text-sm">({optional})</span>
            )}
          </label>
          <input
            type={inputType}
            name={name}
            id={name}
            placeholder=" "
            className={clsx(
              'mt-0 block w-full appearance-none rounded-xl border border-gray-200 bg-transparent px-4 py-2 shadow-sm focus:border-seller focus:border-2 focus:outline-none focus:ring-1',
              {
                'border-rose-500 focus:border-rose-500': hasError,
              },
            )}
            {...props}
            ref={inputRef}
          />
          {type === 'password' && (
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="absolute right-3 top-9 text-gray-400 outline-none transition-all duration-150 focus:text-gray-700"
            >
              {showPassword ? <Eye /> : <EyeOff />}
            </button>
          )}
        </div>
        {hasError && (
          <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => {
              return (
                <div className="text-xsmall-regular pt-1 pl-2 text-rose-500">
                  <span>{message}</span>
                </div>
              );
            }}
          />
        )}
      </div>
    );
  },
);

Input.displayName = 'Input';

export default Input;
