import CatalogButton from '../catalog-button';
import clsx from 'clsx';
import Input from '../input';
import Modal from '../modal';
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { useCart } from '@lib/hooks/use-cart';
import { useStore } from '@lib/context/store-context';
import { buildCart } from '@lib/utils/cart';
import { ICatalogCart } from '@lib/models/view-models/cart';
import React from 'react';
import { useCatalogTranslation } from '@lib/hooks/use-catalog-translations';

type InternalCommandModalProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'placeholder'
> & {
  cart: ICatalogCart;
  isOpen: boolean;
  close: () => void;
  externalIdUpdated?: (updated: boolean) => void;
  externalId?: string;
  setExternalId?: (externalId: string) => void;
  cancelButtonLabel?: string;
  validateButtonLabel?: string;
};

const InternalCommandModal = forwardRef<
  HTMLInputElement,
  InternalCommandModalProps
>(
  (
    {
      cart,
      isOpen,
      close,
      externalIdUpdated,
      externalId,
      setExternalId,
      cancelButtonLabel = 'Annuler',
      validateButtonLabel = 'Associer',
    },
    ref,
  ): JSX.Element => {
    const { updateCurrentCart } = useCart();
    const { setCart } = useStore();
    const [size, setSize] = React.useState<'medium' | 'mobile'>('medium');
    const { ct } = useCatalogTranslation(['cart', 'common']);
    useEffect(() => {
      if (
        size === 'medium' &&
        window.matchMedia('(max-width: 767px)').matches
      ) {
        setSize('mobile');
        return;
      }
      if (
        size === 'mobile' &&
        window.matchMedia('(min-width: 768px)').matches
      ) {
        setSize('medium');
        return;
      }
    });

    const initialValue = externalId || '';

    const cartId = cart?.id;

    const externalIdRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => externalIdRef.current!);

    const cancel = () => {
      if (setExternalId) {
        setExternalId(cart?.externalId);
      }

      close();
    };

    const associate = () => {
      const externalId = externalIdRef.current.value;

      const payload = {
        external_id: externalId,
      };
      updateCurrentCart.mutate(
        { id: cartId, payload },
        {
          onSuccess: (newCart) => {
            setCart(buildCart(newCart));
            if (newCart.external_id && externalIdUpdated) {
              externalIdUpdated(true);
            }
            close();
          },
          onError: (error) => {
            console.error('failed to update cart', error);
          },
        },
      );
    };

    return (
      <Modal isOpen={isOpen} close={close} size={size}>
        <Modal.Body>
          <div className="flex flex-col gap-2">
            <h2 className="font-header text-lg">
              {ct(
                'CartExternalId.title',
                'Associez un numéro de commande interne',
                { ns: 'cart' },
              )}
            </h2>
            <p className="text-base text-grey2-60">
              {ct(
                'CartExternalId.incentive',
                'Vous avez déjà un numéro interne ? Ajoutez le pour un meilleur suivi !',
              )}
            </p>
            <div className="mt-4">
              <Input
                data-testid="inputId"
                label=""
                name="internalCommandIdModal"
                value={initialValue}
                ref={externalIdRef}
                onChange={(e) => {
                  if (setExternalId) {
                    setExternalId(e.target.value);
                  }
                }}
              ></Input>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer size={size}>
          <div
            className={clsx('flex gap-3 mt-6', {
              'justify-end gap-2': size !== 'mobile',
              'w-full': size === 'mobile',
            })}
          >
            <CatalogButton color="grey" type="reset" onClick={cancel}>
              {ct('CommonCta.skip', cancelButtonLabel, { ns: 'common' })}
            </CatalogButton>
            <CatalogButton color="seller" type="submit" onClick={associate}>
              {ct('CommonCta.associate', validateButtonLabel, { ns: 'common' })}
            </CatalogButton>
          </div>
        </Modal.Footer>
      </Modal>
    );
  },
);

InternalCommandModal.displayName = 'InternalCommandModal';

export default InternalCommandModal;
