import React from 'react';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import NoOrderRow from './no-order';
import { v4 } from 'uuid';
import { useOrdersForHomepage } from '@lib/hooks/use-order';
import { useSettings } from '@lib/hooks/use-settings';
import { useCatalogTranslation } from '@lib/hooks/use-catalog-translations';
import ArrowRight from '../../../common/icons/arrow-right';
import OrderCard from '../../../common/components/order-card';

function OrderRow({
  showSmartOrderCard,
}: {
  showSmartOrderCard: boolean;
}): JSX.Element {
  const { ct } = useCatalogTranslation(['homepage', 'common']);
  const fields =
    'metadata,display_id,external_id,status,fulfillment_status,delivery_date,seller_order_id,origin';
  const expand = 'items';
  const { color: sellerColor } = useSettings();
  const { orders } = useOrdersForHomepage({
    fields,
    expand,
    smartOrderDisplayed: showSmartOrderCard,
  });
  const router = useRouter();

  const redirect = (orderId: string) => {
    router.push(`/orders?selected=${orderId}`);
  };

  return (
    <div className="w-full h-auto md:h-[249px] flex flex-col items-center gap-2 rounded-xl bg-grey2-5 p-6">
      <div className="w-full flex flex-col gap-4">
        <div className="flex flex-wrap justify-between items-center w-full basis-full">
          <h2 className="self-start font-header text-xl text-grey2-90">
            {ct('OrderRow.followOrders', 'Suivre mes commandes')}
          </h2>
          <button
            className="font-sans text-base text-seller flex items-center gap-2 group"
            onClick={() => router.push('/orders')}
          >
            <ArrowRight width={16} height={16} color={sellerColor} />
            <span>{ct('CommonCta.seeAll', { ns: 'common' })}</span>
          </button>
        </div>
        {orders && orders.length > 0 ? (
          <div
            className={clsx('grid gap-6', {
              'grid-cols-1': orders.length === 1,
              'grid-cols-2': orders.length === 2,
              'grid-cols-3': orders.length === 3,
            })}
          >
            {orders.map((order) => (
              <div key={v4()}>
                {
                  <OrderCard
                    origin={order.origin}
                    subtotal={order.subtotal}
                    images={order.images}
                    date={order.createdAt}
                    displayId={order.displayId}
                    status={order.computedStatus}
                    totalArticles={order.articlesCount}
                    entityId={order.id}
                    handleClick={redirect}
                    type="complete"
                  />
                }
              </div>
            ))}
          </div>
        ) : (
          <NoOrderRow />
        )}
      </div>
    </div>
  );
}

export default OrderRow;
