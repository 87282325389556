import React, { ReactNode, useEffect, useState } from 'react';
import { flattenClasses } from '../../../../lib/utils/helpers/flatten-classes';

type LabelProps = {
  size: 'xs' | 'sm' | 'lg';
  color:
    | 'red'
    | 'orange'
    | 'blue'
    | 'green'
    | 'gray'
    | 'white'
    | 'black'
    | 'primary';
  children: ReactNode;
};

const Label = ({ size, color, children }: LabelProps) => {
  const [classes, setClasses] = useState(null);

  useEffect(() => {
    const cls = [
      'inline-flex',
      'items-center',
      'justify-center',
      'gap-2',
      'rounded-full',
      'w-fit',
    ];
    switch (size) {
      case 'xs':
        cls.push('h-5', 'px-2', 'text-small', 'leading-5');
        break;
      case 'sm':
        cls.push('h-7', 'px-3', 'text-base', 'leading-5');
        break;
      case 'lg':
        cls.push('h-9', 'px-4', 'text-base', 'leading-5');
        break;
    }

    switch (color) {
      case 'red':
        cls.push('bg-error-10', 'text-error-50');
        break;
      case 'orange':
        cls.push('bg-orange-10', 'text-orange-60');
        break;
      case 'blue':
        cls.push('bg-secondary-10', 'text-secondary-60');
        break;
      case 'green':
        cls.push('bg-success-10', 'text-success-60');
        break;
      case 'gray':
        cls.push('bg-grey2-10', 'text-grey2-60');
        break;
      case 'white':
        cls.push('bg-white', 'text-grey2-60', 'border', 'border-grey2-20');
        break;
      case 'black':
        cls.push('bg-black', 'text-white');
        break;
      case 'primary':
        cls.push('bg-primary-50', 'text-white');
        break;
    }
    setClasses(cls);
  }, []);
  return classes && <div className={flattenClasses(classes)}>{children}</div>;
};

export default Label;
