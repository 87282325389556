import React, { useEffect, useState } from 'react';
import Card from '../../../common/components/card';
import { OrderComputedStatus } from '@lib/models/view-models/order';
import OrderFromCart from './order-from-cart';
import DraftOrderCard from './draft-order-card';
import { OrderTabType } from '@lib/context/order-context';

export type OrderCardProps = {
  displayId: string;
  entityId: string;
  status?: OrderComputedStatus | 'completed' | 'open';
  date: Date;
  origin?: 'cart' | 'document' | 'import' | 'quotation';
  totalArticles: number; // number of items ordered
  images: string[];
  subtotal: number;
  selected?: boolean;
  type: OrderTabType;
  handleClick: (id: string) => void;
};
function OrderCard(props: OrderCardProps): JSX.Element {
  const { selected, type } = props;
  const [view, setView] = useState<JSX.Element>(null);

  useEffect(() => {
    if (type !== 'complete') {
      setView(<DraftOrderCard {...props} />);
    } else {
      setView(<OrderFromCart {...props} />);
    }
    // Pending smart order are not managed anymore in the store
    //   setView(<OrderFromDocument {...props} />);
  }, [type]);

  return (
    <Card
      bgClass="bg-white"
      height={161}
      border
      paddingClass="p-4"
      selected={selected}
    >
      {view}
    </Card>
  );
}

export default OrderCard;
