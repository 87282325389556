import { IAgentLight } from '@lib/models/view-models/agent';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAccount } from '../../../../lib/context/account-context';
import { useCatalogTranslation } from '../../../../lib/hooks/use-catalog-translations';
import Card from '../../../common/components/card';
import AgentView from './agent-view';
import TermsView from './terms-view';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function AgentCard(): JSX.Element {
  const { ct } = useCatalogTranslation('homepage');
  const initalTabs = [
    {
      id: 1,
      name: ct('AgentCard.contactUs', 'Votre contact'),
      current: true,
    },
    {
      id: 2,
      name: ct('AgentCard.terms', 'Voir vos modalités'),
      current: false,
    },
  ];
  const { i18n } = useTranslation();

  const { company } = useAccount();
  const [agent, setAgent] = useState<IAgentLight>(null);
  const [view, setView] = useState<JSX.Element>(null);
  const [currentTab, setCurrentTab] = useState<number>(1);
  const [tabs, setTabs] = useState(initalTabs);

  useEffect(() => {
    setAgent(company?.agent);
  }, [company]);

  useEffect(() => {
    if (tabs[0]?.current) {
      setView(<AgentView agent={agent} />);
    }
  }, [agent]);

  useEffect(() => {
    setTabs(initalTabs);
  }, [i18n.language]);

  useEffect(() => {
    setTabs(tabs.map((t) => ({ ...t, current: t.id === currentTab })));
    if (currentTab === 1) {
      setView(<AgentView agent={agent} />);
    } else {
      setView(
        <TermsView
          franco={company.franco}
          paymentTerms={company.paymentTerms}
        />,
      );
    }
  }, [currentTab]);

  return (
    <Card height={249} paddingClass="p-4" border={true}>
      <div className="px-2 pt-1" data-testid="agent-card">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => (
              <span
                onClick={() => setCurrentTab(tab.id)}
                key={tab.name}
                className={classNames(
                  tab.current
                    ? 'border-seller text-black'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium cursor-pointer',
                )}
                aria-current={tab.current ? 'page' : undefined}
              >
                {tab.name}
              </span>
            ))}
          </nav>
        </div>
        {view}
      </div>
    </Card>
  );
}

export default AgentCard;
