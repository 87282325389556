import React from 'react';

type SplitLineProps = {
  lines: string;
};
const SplitLine = ({ lines }: SplitLineProps) => {
  return (
    <>
      {lines.split('\\n').map((line, index) => {
        return (
          <span key={index}>
            {line}
            <br />
          </span>
        );
      })}
    </>
  );
};

export default SplitLine;
