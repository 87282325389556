import React, { useState } from 'react';
import Card from '../../../common/components/card';
import { Import } from 'iconoir-react';
import SplitLine from '../../../common/components/split-line';
import { useCatalogTranslation } from '../../../../lib/hooks/use-catalog-translations';
import CatalogButton from '../../../common/components/catalog-button';
import CatalogModal from '../../../common/components/catalog-modal';
import UploadOrderModal from './upload-order-modal';

const SmartOrderCard = ({ hoverEffect = true }: { hoverEffect?: boolean }) => {
  const [openModal, setOpenModal] = useState(false);
  const [buttonColor, setButtonColor] = useState<'seller' | 'grey'>('grey');
  const { ct } = useCatalogTranslation('homepage');
  const handleClick = () => {
    setOpenModal(true);
  };
  return (
    <>
      <div
        className="relative w-full h-[244px] cursor-pointer rounded-xl group shadow-xs hover:shadow-base"
        onClick={handleClick}
        onMouseEnter={() => setButtonColor('seller')}
        onMouseLeave={() => setButtonColor('grey')}
      >
        {hoverEffect && (
          <div className="absolute cursor-pointer rounded-lg inset-2 bg-gradient-to-tr duration-500 from-seller/10 to-seller/0 transition-opacity opacity-0 w-[calc(100%-16px)] h-[calc(100%-16px)] group-hover:opacity-100"></div>
        )}
        <Card border={true} height={244} bgClass="bg-transparent">
          <div className="mt-auto flex justify-between items-end">
            <div className="translate-y-12 group-hover:translate-y-0 duration-500 transition-transform">
              <div className="relative w-full flex flex-1 items-end">
                <div className="relative">
                  <Import className="w-11 h-11 text-red-500" />
                </div>
              </div>
              <div className="flex flex-col">
                <h2 className="font-header text-xl color-grey2-90">
                  <SplitLine
                    lines={ct(
                      'SmartOrderCard.title',
                      'Importer un bon de \n commande',
                    )}
                  />
                </h2>
                <p className="text-base font-sans text-grey2-60 transition-opacity  duration-500 opacity-0 group-hover:opacity-100">
                  <SplitLine
                    lines={ct(
                      'SmartOrderCard.desc',
                      'Commandez rapidement en \n important un bon de commande ',
                    )}
                  />
                </p>
              </div>
            </div>
            <CatalogButton color={buttonColor} width="fit">
              <Import className="w-4 h-4" />
              {ct('SmartOrderCard.button', 'Importer')}
            </CatalogButton>
          </div>
        </Card>
      </div>
      <CatalogModal open={openModal} setOpen={setOpenModal} width={440}>
        <UploadOrderModal setOpen={setOpenModal} />
      </CatalogModal>
    </>
  );
};

export default SmartOrderCard;
