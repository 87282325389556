import React, { useEffect, useState } from 'react';
import Card from '../../../common/components/card';
import { ArrowRight } from 'iconoir-react';
import { useProductCategories } from '../../../../lib/hooks/use-product-category';
import { useRouter } from 'next/router';
import { useCatalogTranslation } from '../../../../lib/hooks/use-catalog-translations';
import SplitLine from '../../../common/components/split-line';
import ExploreCatatlogThumbnail from './thumbnail';

const CatalogCard = () => {
  const { ct } = useCatalogTranslation('homepage');
  const defaultColor = '#6B6868'; // default grey2-60
  const whiteColor = '#ffffff';
  const [iconColor, setIconColor] = useState(defaultColor);
  const fields = 'id,name,thumbnail,handle';
  const parent_category_id = 'null';
  const expand = '';
  const { categories } = useProductCategories({
    fields,
    parent_category_id,
    expand,
  });

  const [categoriesImg, setCategoriesImg] = useState<
    { src: string; alt: string }[]
  >([]);

  useEffect(() => {
    if (categories) {
      const imgs = categories.map((category) => {
        return {
          src: category.thumbnail,
          alt: category.name,
        };
      });
      setCategoriesImg(imgs);
    }
  }, [categories]);

  const [images, setImages] = useState<{ src: string; alt: string }[]>([]);
  const [imageSrcError, setImageSrcError] = useState<string>(null);
  const router = useRouter();

  useEffect(() => {
    if (imageSrcError) {
      setCategoriesImg(
        categoriesImg.filter((img) => img.src !== imageSrcError),
      );
    }
  }, [imageSrcError]);

  useEffect(() => {
    if (categoriesImg) {
      const imgs = categoriesImg.slice(0, 3);
      setImages(imgs);
    }
  }, [categoriesImg]);

  return (
    <div
      data-testid="catalog-card"
      className="relative w-full h-[244px] cursor-pointer rounded-xl group shadow-xs hover:shadow-sm"
      onMouseEnter={() => setIconColor(whiteColor)}
      onMouseLeave={() => setIconColor(defaultColor)}
      onClick={() => router.push('/products')}
    >
      <div className="homepage-card-wrapper"></div>
      <Card bgClass="bg-transparent" border={true} height={244}>
        <div className="relative w-full mt-auto flex flex-col gap-4 justify-end">
          <div className="flex gap-4 translate-y-0 group-hover:translate-y-[2px]">
            {images.map((image, index) => {
              return (
                <ExploreCatatlogThumbnail
                  key={index}
                  image={image}
                  setImageSrcError={setImageSrcError}
                />
              );
            })}
          </div>
          <div className="flex justify-between items-end">
            <h2 className="font-header text-xl color-grey2-90">
              <SplitLine lines={ct('CatalogCard.explore')} />
            </h2>
            <button className="bg-grey2-10 w-10 h-10 group-hover:bg-seller duration-700 text-white flex items-center justify-center transition-all rounded-full">
              <ArrowRight width={16} height={16} color={iconColor} />
            </button>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default CatalogCard;
