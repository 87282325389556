import React from 'react';
import clsx from 'clsx';
import AgentCard from '../components/agent-card';
import CatalogCard from '../components/catalog-card';
import ExpressBuyCard from '../components/express-buy';
import WelcomeCard from '../components/welcome-card';
import OrderRow from '../components/order-row';
import FooterLinks from '../components/footer-links';
import Chat from '@modules/common/components/chat';
import ChristmasCard from '../components/christmas-card';
import {
  useChristmasBannerSettings,
  useSettings,
} from '@lib/hooks/use-settings';
import { useAccount } from '@lib/context/account-context';
import SmartOrderCard from '../components/smart-order-card';
import PromoCard from '../components/promo-card';

const HomePageTemplate = () => {
  const { christmasBanner, christmasBannerColor } =
    useChristmasBannerSettings();
  const { allowSmartOrderUpload, promoBanner, intercomActive } = useSettings();
  const { customer } = useAccount();

  return (
    customer.hasAccount && (
      <div className="px-4 xl:px-20">
        <section className="xl:container mx-auto grid gird-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 py-12">
          <div className="order-1">
            <WelcomeCard />
          </div>
          <div
            className={clsx('hidden sm:block', {
              'sm:order-2': allowSmartOrderUpload,
              'sm:order-3': !allowSmartOrderUpload,
            })}
          >
            <CatalogCard />
          </div>
          {allowSmartOrderUpload && (
            <div className="order-3">
              <SmartOrderCard hoverEffect={true} />
            </div>
          )}
          <div
            className={clsx({
              'order-6': allowSmartOrderUpload,
              'order-2': !allowSmartOrderUpload,
            })}
          >
            <ExpressBuyCard hoverEffect />
          </div>
          {christmasBanner && (
            <div className="lg:col-span-3 sm:col-span-2 order-4">
              <ChristmasCard bgColor={christmasBannerColor} />
            </div>
          )}
          {promoBanner && (
            <div className="lg:col-span-3 sm:col-span-2 order-4">
              <PromoCard />
            </div>
          )}
          {/* {productDiscountDescs?.length > 0 && (
            <div className="lg:col-span-3 sm:col-span-2">
              <DiscountCard discountDescs={productDiscountDescs} />
            </div>
          )} */}
          <div className="order-5">
            <AgentCard />
          </div>
          <div
            className={clsx('order-7', {
              'sm:col-span-2': !allowSmartOrderUpload,
            })}
          >
            <OrderRow showSmartOrderCard={allowSmartOrderUpload} />
          </div>
        </section>
        {intercomActive && (
          <Chat
            message="Bonjour,
Je vous contacte car je rencontre un problème technique ou j'ai une suggestion d'amélioration sur la plateforme:"
          />
        )}
        <FooterLinks />
      </div>
    )
  );
};

export default HomePageTemplate;
