import React from 'react';
import clsx from 'clsx';
import Image from 'next/image';
import Card from '../../../common/components/card';
import Label from '../../../common/components/label';
import { Gift } from 'iconoir-react';
import { useCatalogTranslation } from '../../../../lib/hooks/use-catalog-translations';
import { Trans, useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';

const ChristmasCard = ({ bgColor }: { bgColor: string }) => {
  const route = useRouter();

  const { ct } = useCatalogTranslation('homepage');
  const { t } = useTranslation('homepage');
  const label = ct('DiscountCard.wildTribeBadge', 'On a un cadeau pour vous');
  const handleRedirect = () => {
    const path = '/products?handle=wild-tribe-anniversary';
    route.push(path);
  };
  return (
    <div
      onClick={handleRedirect}
      style={{ backgroundColor: bgColor }}
      className={clsx(
        'relative w-full min-h-[244px] shadow-xs hover:shadow-sm group rounded-xl cursor-pointer',
      )}
    >
      <div className="homepage-card-wrapper"> </div>
      <Card bgClass="bg-transparent flex flex-col gap-4 md:block">
        <div className="relative w-full h-full flex flex-col gap-11 justify-end">
          <Label size="sm" color="black">
            <Gift className="w-4 h-4 text-white" />
            {label}
          </Label>
          <div className="flex flex-col gap-3">
            <Gift className="h-10 w-10" />
            <h2 className="font-header text-xl leading-6 text-black">
              {ct(
                'DiscountCard.wildTribeTitle',
                'Offre Wholesale Wild Tribe Anniversary Even Wilder',
              )}
            </h2>
            <p className="text-black text-base w-3/4">
              <Trans t={t} i18nKey="DiscountCard.wildTribeDesc">
                À l’achat de plus de <strong>30</strong> produits de notre
                capsule <i>Wild Tribe</i> anniversary, vous recevrez 3
                exemplaires du modèle <i>Custine</i> en guise de cadeau. Les
                modèles seront sélectionnés dans notre stock de façon aléatoire.
                (Fonctionne en multiple : <strong>60</strong> articles achetés =
                <strong>6</strong> exemplaires offerts)
              </Trans>
            </p>
          </div>
        </div>
      </Card>
      <div className="mx-auto md:mx-0 md:absolute md:top-1/2 md:-translate-y-1/2 md:right-10 w-[244px] h-[244px]">
        <Image
          src="/wild-tribe.png"
          alt="selection de noël"
          width={244}
          height={244}
          layout="responsive"
        />
      </div>
    </div>
  );
};

export default ChristmasCard;
