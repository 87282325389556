import React, { useEffect, useState } from 'react';

type AvatarProps = {
  firstname?: string | null;
  lastname?: string | null;
  charsLength?: 1 | 2;
  email: string;
  radius?: 'full' | 'squared';
  color?: string;
  size?: 'sm' | 'md' | 'lg';
};

const Avatar = ({
  firstname,
  lastname,
  charsLength = 1,
  email,
  radius = 'squared',
  color = 'bg-secondary-10 text-secondary-50',
  size = 'sm',
}: AvatarProps) => {
  const [initial, setInitial] = useState<string | null>(null);

  useEffect(() => {
    if (charsLength === 1) {
      if (firstname) {
        setInitial(firstname.charAt(0).toUpperCase());
      } else if (email) {
        setInitial(email.charAt(0).toUpperCase());
      } else {
        setInitial('c');
      }
    } else {
      if (firstname && lastname) {
        setInitial(`${firstname.charAt(0)}${lastname.charAt(0)}`.toUpperCase());
      } else if (email) {
        setInitial(email.charAt(0).toUpperCase());
      } else {
        setInitial('c');
      }
    }
  }, [firstname, lastname, email, charsLength]);

  const defineClasses = () => {
    const base = [
      'flex',
      'items-center',
      'justify-center',
      'ring-2',
      'ring-white',
      color,
    ];
    switch (radius) {
      case 'full':
        base.push('rounded-full');
        break;
      case 'squared':
        base.push('rounded-lg');
    }
    switch (size) {
      case 'sm':
        base.push('w-6', 'h-6', 'text-small', 'leading-3');
        break;
      case 'md':
        base.push('w-12', 'h-12', 'text-medium', 'leading-4');
        break;
      case 'lg':
        base.push('w-16', 'h-16', 'text-large', 'leading-5');
        break;
    }

    return base.join(' ');
  };

  return (
    <div className={defineClasses()}>
      <span className="font-header">{initial} </span>
    </div>
  );
};

export default Avatar;
