import Head from '@modules/common/components/head';
import { ReactElement } from 'react';
import { useColorsSettings } from '../lib/hooks/use-settings';
import HomePageTemplate from '../modules/home/templates';
import CatalogLayout from '../modules/layout/templates/catalog-layout';
import { NextPageWithLayout } from '../types/global';

const Home: NextPageWithLayout = () => {
  const { sellerColor, sellerFontColor } = useColorsSettings();

  return (
    <>
      <Head
        title="Home"
        description="Catalog Store Home Page"
        style={`:root {${sellerColor} ${sellerFontColor}}`}
      />
      <HomePageTemplate />
    </>
  );
};

Home.getLayout = (page: ReactElement) => {
  return <CatalogLayout>{page}</CatalogLayout>;
};

export default Home;
