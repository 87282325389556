import React from 'react';
import { useSettings } from '@lib/hooks/use-settings';
import Image from 'next/image';
import Link from 'next/link';
import { useAmplitudeContext } from '@lib/context/analytics-context';

const PromoCard = () => {
  const {
    promoLink,
    promoBgColor,
    promoBannerUrl: promoImageUrl,
  } = useSettings();
  const { trackAmplitudeEvent } = useAmplitudeContext();

  const handleClick = () => {
    trackAmplitudeEvent('Promotion clicked', { source: 'homepage' });
  };

  return (
    <Link href={promoLink} passHref>
      <a
        style={{ backgroundColor: promoBgColor }}
        className="block relative w-full min-h-[244px] shadow-xs hover:shadow-sm group rounded-xl cursor-pointer"
        onClick={handleClick}
      >
        <Image
          src={promoImageUrl}
          alt="promotion"
          layout="fill"
          objectFit="contain"
          objectPosition="center"
          className="w-full h-auto"
        />
      </a>
    </Link>
  );
};

export default PromoCard;
