import Tag from '../../../modules/common/components/tag';
import { useCatalogTranslation as UseCatalogTranslation } from '../../hooks/use-catalog-translations';
import {
  IFulfillment,
  OrderComputedStatus,
  OrderPaymentStatus,
} from '../../models/view-models/order';

export const statusMapper = (
  computedStatus: OrderComputedStatus,
  size: 'sm' | 'md' | 'lg' = 'sm',
  document = false,
): JSX.Element => {
  const { ct } = UseCatalogTranslation('common');
  if (!computedStatus) return null;
  switch (computedStatus) {
    case OrderComputedStatus.CANCELED:
      return (
        <Tag
          color="red"
          size={size}
          label={ct('OrderStatus.canceled', 'Annulée')}
        />
      );
    case OrderComputedStatus.REJECTED:
      return (
        <Tag
          color="red"
          size={size}
          label={ct('OrderStatus.rejected', 'Rejetée')}
        />
      );
    case OrderComputedStatus.RESERVED:
      return (
        <Tag
          color="grey"
          size={size}
          label={ct('OrderStatus.reserved', 'Réservée')}
        />
      );
    case OrderComputedStatus.PENDING:
      return (
        <Tag
          color="orange"
          size={size}
          label={
            document
              ? ct('OrderStatus.processing', 'En traitement')
              : ct('OrderStatus.pending', 'En validation')
          }
        />
      );
    case OrderComputedStatus.VALIDATED:
      return (
        <Tag
          color="blue"
          size={size}
          label={ct('OrderStatus.validated', 'Validée')}
        />
      );
    case OrderComputedStatus.PARTIALLY_FULFILLED:
      return (
        <Tag
          color="blue"
          size={size}
          label={ct('OrderStatus.inProgress', 'En préparation')}
        />
      );
    case OrderComputedStatus.READY_TO_SHIP:
      return (
        <Tag
          color="blue"
          size={size}
          label={ct('OrderStatus.readyToShip', 'Prêt pour expédition')}
        />
      );
    case OrderComputedStatus.PARTIALLY_SHIPPED:
      return (
        <Tag
          color="light-green"
          size={size}
          label={ct('OrderStatus.partiallyShipped', 'Partiellement expédiée')}
        />
      );
    case OrderComputedStatus.SHIPPED:
      return (
        <Tag
          color="green"
          size={size}
          label={ct('OrderStatus.shipped', 'Expédiée')}
        />
      );
    default:
      return (
        <Tag
          color="blue"
          size={size}
          label={ct('OrderStatus.pending', 'En attente')}
        />
      );
  }
};

export const paymentStatusMapper = (
  paymentStatus: OrderPaymentStatus,
  size: 'sm' | 'md' | 'lg' = 'sm',
): JSX.Element => {
  const { ct } = UseCatalogTranslation('common');
  switch (paymentStatus) {
    case OrderPaymentStatus.PAID:
      return (
        <Tag
          color="green"
          size={size}
          label={ct('OrderStatus.paid', 'Payée')}
        />
      );
    case OrderPaymentStatus.PARTIALLY_PAID:
      return (
        <Tag
          color="blue"
          size={size}
          label={ct('OrderStatus.partiallyPaid', 'Paiement partiel')}
        />
      );
    case OrderPaymentStatus.NOT_PAID:
    default:
      return (
        <Tag
          color="red"
          size={size}
          label={ct('OrderStatus.notPaid', 'Non payée')}
        />
      );
  }
};

export const fulfillmentStatusMapper = (fulfillment: IFulfillment) => {
  const { ct } = UseCatalogTranslation('common');

  if (!fulfillment) return null;

  if (fulfillment.shippedAt) {
    return (
      <Tag
        color="green"
        size="sm"
        label={ct('OrderStatus.fulfillmentShipped', 'Expédié')}
      />
    );
  }

  if (fulfillment.canceled) {
    return (
      <Tag
        color="red"
        size="sm"
        label={ct('OrderStatus.fulfillmentCanceled', 'Annulé')}
      />
    );
  }

  return (
    <Tag
      color="blue"
      size="sm"
      label={ct('OrderStatus.fulfillmentReady', 'Prêt pour expédition')}
    />
  );
};
