import React, { useEffect, useState } from 'react';
import Card from '../../../common/components/card';
import ExpressByEmptyState from './express-buy-empty-state';
import ExpressBuyFilledState from './express-buy-filled-state';
import { ArrowRight } from 'iconoir-react';
import { useStore } from '../../../../lib/context/store-context';
import { useRouter } from 'next/router';
import InternalCommandModal from '@modules/common/components/internal-command-modal';
import useToggleState from '@lib/hooks/use-toggle-state';
import { useCatalogTranslation } from '../../../../lib/hooks/use-catalog-translations';
import { useTranslation } from 'react-i18next';
import EmptyStateButton from './empty-state-button';
import FilledStateButton from './filled-state-button';

type ExpressBuyCardType = {
  hoverEffect?: boolean;
};
const ExpressBuyCard = ({ hoverEffect = true }: ExpressBuyCardType) => {
  const { ct } = useCatalogTranslation('homepage');
  const { i18n } = useTranslation();
  const { cart } = useStore();
  const [emptyState, setEmptyState] = useState<boolean>(true);
  const [buttonLabel, setButtonLabel] = useState<string>(null);
  const [button, setButton] = useState<JSX.Element>(null);
  const [view, setView] = useState<JSX.Element>(null);
  const [externalId, setExternalId] = useState<string>(cart?.externalId);

  const router = useRouter();

  useEffect(() => {
    if (cart?.items?.length > 0) {
      setEmptyState(false);
    } else {
      setEmptyState(true);
    }

    if (cart) {
      setExternalId(cart.externalId);
    }
  }, [cart]);

  useEffect(() => {
    if (emptyState) {
      setView(<ExpressByEmptyState />);
      setButtonLabel('');
    } else {
      setView(
        <ExpressBuyFilledState
          items={cart?.items}
          totalHT={cart?.subtotal + cart?.shippingTotal}
        />,
      );
      setButtonLabel(ct('ExpressBuyCard.filledStateCta', 'Continuer'));
    }
  }, [emptyState, cart, i18n.language]);

  useEffect(() => {
    if (emptyState) {
      setButton(<EmptyStateButton />);
    } else {
      setButton(
        <FilledStateButton>
          <div className="opacity-100 transition-opacity duration-500 group-hover:hidden group-hover:opacity-0">
            {buttonLabel}
          </div>
          <ArrowRight width={16} height={16} color="#ffffff" />
        </FilledStateButton>,
      );
    }
  }, [buttonLabel]);

  const externalIdRef = React.useRef<HTMLInputElement>(null);

  const { open, close, state } = useToggleState();

  const [externalIdUpdated, isExternalIdUpdated] = useState(false);

  useEffect(() => {
    if (externalIdUpdated) {
      router.push('/cart');
      isExternalIdUpdated(false);
    }
  }, [externalIdUpdated]);

  const handleClick = () => {
    if (emptyState) {
      open();
    } else {
      router.push('/cart');
    }
  };

  const handleSkipClick = () => {
    close();
    router.push('/cart');
  };

  return (
    <div
      className="relative w-full h-[244px] cursor-pointer rounded-xl group shadow-xs hover:shadow-base"
      onClick={handleClick}
    >
      {hoverEffect && (
        <div className="absolute cursor-pointer rounded-lg inset-2 bg-gradient-to-tr duration-500 from-seller/10 to-seller/0 transition-opacity opacity-0 w-[calc(100%-16px)] h-[calc(100%-16px)] group-hover:opacity-100"></div>
      )}
      <Card border={true} height={244} bgClass="bg-transparent">
        <div className="relative w-full flex flex-1 items-end">
          <div className="flex-grow flex items-end">
            {view}
            {button}
            <InternalCommandModal
              cart={cart}
              isOpen={state}
              close={handleSkipClick}
              ref={externalIdRef}
              externalIdUpdated={isExternalIdUpdated}
              cancelButtonLabel="Passer"
              externalId={externalId}
              setExternalId={setExternalId}
            />
          </div>
        </div>
      </Card>
    </div>
  );
};

export default ExpressBuyCard;
