import { ArrowRight } from 'iconoir-react';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { IButtonProps } from '../../../common/components/catalog-button';

const EmptyStateButton = forwardRef<HTMLButtonElement, IButtonProps>(
  (_props, ref) => {
    const innerRef = useRef<HTMLButtonElement>(null);
    useImperativeHandle(ref, () => innerRef.current!);

    return (
      <button
        ref={innerRef}
        className="absolute bottom-0 right-0 flex items-center justify-center h-10 w-10 rounded-full bg-grey2-10 transition-colors group-hover:bg-seller"
      >
        <ArrowRight className="h-4 w-4 transition-colors text-grey2-60 group-hover:text-white" />
      </button>
    );
  },
);

EmptyStateButton.displayName = 'EmptyStateButton';

export default EmptyStateButton;
